<template>
  <v-app class="bg">
    <v-main style="margin-top: 20px">
      <v-card
          class="mx-auto my-12"
          color="white"
          max-width="75%"
          style="padding: 5%"
      >
        <v-row class="justify-center">
          <v-img
              t-data="hospital-logo"
              :src="hospitalLogo"
              alt=""
              width="360"
              max-width="65%">
          </v-img>
        </v-row>
        <v-row class="justify-center" style="margin-left: 20px; margin-top: 20px" t-data="sign-up-content">
          <h3>Welcome to {{ appName }} mobile application. Please tap the button below to register.</h3>
        </v-row>
        <v-row class="justify-center" t-data="regis-btn">
          <v-btn x-large dark style="margin-top: 20px;" color="primary" :href="url" text>Register</v-btn>
        </v-row>
      </v-card>
    </v-main>
  </v-app>
</template>
<script>
import {imageHospitalLogo, mobileAppName} from '@/constants/constants'

  export default {
    data() {
      return {
        url: '',
      }
    },
    mounted() {
      this.url = Buffer.from(this.$route.query.url, 'base64').toString('ascii')
    },
    computed: {
      appName() {
        return mobileAppName
      },
      hospitalLogo() {
        return imageHospitalLogo
      },
    },
  }
</script>

<style>
</style>
